import { useParams, useLocation } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import Header from "../components/Header";

export function Paint() {
  const { paintId } = useParams();
  //   const location = useLocation();
  //   const { title } = location.state;
  const { loading, error, data } = useFetch(
    `https://srh-h8t3q.ondigitalocean.app/api/paints/${paintId}?populate=image`
  );

  if (loading) return <p> Loading... </p>;
  if (error) return <p> Error :( </p>;

  return (
    <div>
      <Header />
      <section className="container">
        <div className="flex flex-wrap">
          <div className="w-column-12 md:w-column-8">
            <img
              src={`${data.attributes.image.data.attributes.url}`}
              alt={data.attributes.image.data.attributes.alternativeText}
              className="w-full"
            />
          </div>
          <div className="w-column-12 md:w-column-4 mt-2 md:mt-0">
            <h1 className="text-2xl md:text-5xl md:leading-relaxed text-center lg:text-left">
              {data.attributes.title}
            </h1>
            <span className="text-lg">{data.attributes.size}</span>
            <div className="rich-description text-baseLight mt-1">
              {data.attributes.description}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
