import React from "react";
import useFetch from "../hooks/useFetch";
import "./../scss/hero.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link } from "react-router-dom";

const Hero = () => {
  const { loading, error, data } = useFetch(
    `https://srh-h8t3q.ondigitalocean.app/api/hero?populate=paints&populate=paints.image`
  );

  if (loading) return <p> Loading... </p>;
  if (error) return <p> Error :( </p>;

  const heroPaints = [];
  const heroPaintsMobile = [];
  data.attributes.paints.data.forEach((el, index) => {
    heroPaints.push(
      <div
        className={`w-1/3 lg:w-auto rounded-2xl overflow-hidden hero-image ${
          index === 0 ? "is-hover" : ""
        }`}
        onMouseEnter={addWidth}
        onMouseLeave={removeWidth}
        key={el.id}
      >
        <Link to={`galery/${el.id}`}>
          <img
            className="w-full h-full object-cover"
            src={`${el.attributes.image.data.attributes.url}`}
            alt={el.attributes.image.data.attributes.alternativeText}
          />
        </Link>
      </div>
    );
  });

  data.attributes.paints.data.forEach((el) => {
    heroPaintsMobile.push(
      <SwiperSlide key={el.id} className="w-auto">
        <div className="rounded-2xl overflow-hidden h-full hero-image-mobile">
          <a href="/">
            <img
              className="w-full h-full object-cover"
              src={`${el.attributes.image.data.attributes.url}`}
              alt={el.attributes.image.data.attributes.alternativeText}
            />
          </a>
        </div>
      </SwiperSlide>
    );
  });

  function addWidth(e) {
    const element = e.currentTarget;
    const parent = element.parentNode;
    const childs = parent.childNodes;

    if (element !== childs[0] && childs[0].classList.contains("is-hover")) {
      childs[0].classList.remove("is-hover");
    }

    element.classList.add("is-hover");
  }

  function removeWidth(e) {
    const element = e.currentTarget;
    const parent = element.parentNode;
    const childs = parent.childNodes;

    if (element !== childs[0]) {
      element.classList.remove("is-hover");
    }

    childs[0].classList.add("is-hover");
  }

  return (
    <section className="container">
      <div className="flex items-center flex-wrap overflow-hidden mt-5 lg:mt-0">
        <div className="w-column-12 lg:w-column-4">
          <h1 className="text-4xl md:text-5xl md:leading-relaxed text-center lg:text-left">
            {data.attributes.title}
          </h1>
        </div>
        <div className="hidden w-column-8 lg:flex gap-x-2 hero-images-container">
          {heroPaints}
        </div>

        <div className="w-column-12 mt-4 hero-images-container lg:hidden">
          <Swiper spaceBetween={16} slidesPerView={"auto"} className="h-full">
            {heroPaintsMobile}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Hero;
