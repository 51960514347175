import React from "react";
import useFetch from "../hooks/useFetch";
import Showdown from "showdown";

const News = () => {
  const { loading, error, data } = useFetch(
    `https://srh-h8t3q.ondigitalocean.app/api/new?populate=image`
  );

  if (loading) return <p> Loading... </p>;
  if (error) return <p> Error :( </p>;

  const isImage = data.attributes.image.data != null ? true : false;
  const converter = new Showdown.Converter();

  return (
    <section className="bg-grey mt-8 py-8">
      <div className="container">
        <div className="w-column-12 lg:w-column-6">
          <h3 className="font-heading text-lg mb-4 text-primary font-bold">
            {data.attributes.subtitle}
          </h3>
          <h2 className="font-heading text-4xl">{data.attributes.title}</h2>
        </div>
        <div className="flex flex-col sm:flex-row mt-4">
          {isImage && (
            <div className="w-column-12 sm:w-column-6 mb-2 sm:mb-0">
              <div className="rounded-2xl overflow-hidden">
                <img
                  src={`${data.attributes.image.data.attributes.url}`}
                  alt={data.attributes.image.data.attributes.alternativeText}
                  loading="lazy"
                />
              </div>
            </div>
          )}

          <div
            className="w-column-12 sm:w-column-6 rich-description text-baseLight"
            dangerouslySetInnerHTML={{
              __html: converter.makeHtml(data.attributes.description)
            }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default News;
