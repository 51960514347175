import React from "react";
import useFetch from "../hooks/useFetch";
import "./../scss/latest.scss";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const Latest = () => {
  const { loading, error, data } = useFetch(
    `https://srh-h8t3q.ondigitalocean.app/api/paints?populate=image`
  );

  //   const swiper = useSwiper();
  if (loading) return <p> Loading... </p>;
  if (error) return <p> Error :( </p>;

  const latestArray = [];
  const reverseArray = [...data].reverse();
  reverseArray.forEach((el) => {
    latestArray.push(
      <SwiperSlide key={el.id} className="w-auto">
        <div className="rounded-2xl overflow-hidden h-full">
          <img
            src={`${el.attributes.image.data.attributes.url}`}
            alt={el.attributes.image.data.attributes.alternativeText}
            className="w-full h-full object-cover"
          />
        </div>
      </SwiperSlide>
    );
  });
  return (
    <section className="py-8">
      <div className="container">
        <div className="w-column-12">
          <h2 className="font-heading text-lg mb-4 text-primary font-bold">
            Mes dernières créations
          </h2>

          <div className="latest-images-container">
            <Swiper
              breakpoints={{
                640: {
                  slidesPerView: 2.5
                },
                768: {
                  slidesPerView: 3
                }
              }}
              spaceBetween={16}
              pagination={{
                el: ".test",
                dynamicBullets: true,
                type: "bullets"
              }}
              modules={[Pagination]}
              className="h-full"
            >
              {latestArray}
            </Swiper>
            <div className="test mx-auto mt-2"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Latest;
