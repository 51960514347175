import Header from "../components/Header";

export function Contact() {
  return (
    <>
      <Header></Header>
      <section className="container">
        <div className="w-column-12">
          <h1 className="text-4xl md:text-5xl md:leading-relaxed text-center lg:text-left">
            Contactez moi !
          </h1>

          <div className="rich-description mt-4">
            <p>
              Vous pouvez me contacter par mail ici:
              uneadressemail@quelquechose.com
            </p>
            <p>
              Ou alors me suivre sur instagram <a href="/">ici</a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
