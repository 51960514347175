import React, { useState } from "react";
import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import "./../scss/header.scss";

const Header = () => {
  const { loading, error, data } = useFetch(
    `https://srh-h8t3q.ondigitalocean.app/api/menus`
  );
  const [isOpen, setIsOpen] = useState(false);

  if (loading) return <p> Loading... </p>;
  if (error) return <p> Error :( </p>;

  const navLinks = [];
  data.forEach((el) => {
    navLinks.push(
      <li className="h-8 overflow-hidden z-30" key={el.id}>
        <Link
          className="text-3xl py-1_5 inline-block nav-link"
          to={`/${el.attributes.url}`}
        >
          {el.attributes.pageName}
        </Link>
      </li>
    );
  });
  function handleMenuClick() {
    setIsOpen(!isOpen);
  }
  return (
    <header className="container">
      <div
        className={`w-column-12 py-2 md:py-4 relative ${
          isOpen ? "is-open" : ""
        }`}
      >
        <div className="menu-background fixed top-0 left-0 w-full h-screen bg-black z-20"></div>

        <div className="flex justify-between items-center flex-wrap">
          <Link
            className="text-4xl font-heading z-30 flex items-baseline"
            to="/"
          >
            <div className="h-5 overflow-hidden">
              <div
                className={`flex flex-col text-right menu-words ${
                  isOpen ? "text-white" : "text-black"
                }`}
              >
                <span>SRH</span>
                <span>SRH</span>
              </div>
            </div>
            <span className="block h-1 w-1 rounded-full ml-1 z-30 bg-primary"></span>
          </Link>

          <div
            className="flex items-center cursor-pointer"
            onClick={handleMenuClick}
          >
            <div className="h-3 overflow-hidden z-30">
              <div
                className={`flex flex-col text-right menu-words ${
                  isOpen ? "text-white" : "text-black"
                }`}
              >
                <span className="block uppercase">Menu</span>
                <span className="block uppercase">Close</span>
              </div>
            </div>
            <span
              className={`block h-1 w-1 rounded-full ml-1_5 z-30 menu-dot ${
                isOpen ? "bg-white" : "bg-black"
              }`}
            ></span>
          </div>
        </div>
        <nav
          className={`z-30 text-white absolute top-full menu-nav ${
            isOpen ? "" : "overflow-hidden pointer-events-none invisible"
          }`}
        >
          <ul className="flex flex-col">{navLinks}</ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
