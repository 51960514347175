import Header from "../components/Header";
import Hero from "../components/Hero";
import News from "../components/News";
import Latest from "../components/Latest";

export function Home() {
  return (
    <>
      <Header></Header>
      <Hero></Hero>
      <News></News>
      <Latest></Latest>
    </>
  );
}
