// import Header from "./components/Header";
// import Hero from "./components/Hero";
// import News from "./components/News";
// import Latest from "./components/Latest";
import { HashRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home";
import { Galery } from "./pages/galery";
import { Paint } from "./pages/paint";
import { Contact } from "./pages/contact";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/galery" element={<Galery />} />
          <Route path="/galery/:paintId" element={<Paint />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;

{
  /* <div className="App">
<Header></Header>
<Hero></Hero>
<News></News>
<Latest></Latest>
</div> */
}
