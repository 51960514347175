import useFetch from "../hooks/useFetch";
import Header from "../components/Header";
import { Link } from "react-router-dom";

export function Galery() {
  const { loading, error, data } = useFetch(
    `https://srh-h8t3q.ondigitalocean.app/api/paints?populate=image`
  );

  if (loading) return <p> Loading... </p>;
  if (error) return <p> Error :( </p>;

  const paintsArray = [];
  data.forEach((el) => {
    console.log(el);
    paintsArray.push(
      <div className="overflow-hidden max-h-galery h-full flex items-center">
        <Link to={`${el.id}`} state={{ title: el.attributes.title }}>
          <img
            src={`${el.attributes.image.data.attributes.url}`}
            alt={el.attributes.image.data.attributes.alternativeText}
            className="w-full"
          />
        </Link>
      </div>
    );
  });
  return (
    <div>
      <Header />
      <section className="container">
        <div className="w-column-12">
          <h1 className="text-4xl md:text-5xl md:leading-relaxed text-center lg:text-left">
            Galerie des mes tableaux
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2 mt-4">
            {paintsArray}
          </div>
        </div>
      </section>
    </div>
  );
}
